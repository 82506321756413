import App from 'components/App/App';
import React, { Suspense } from 'react';
import TeamsRoutes from 'routes/teams';
import { createRoot } from 'react-dom/client';
import '@matterapp/matter-fonts/build/fonts.css';
import CssBaseline from '@mui/material/CssBaseline';
import { Resources } from '@matterapp/routing';
import Loader from 'components/Loader/Loader';
import mixpanel from 'mixpanel-browser';

mixpanel.init(ENV_VARS.MIXPANEL_TOKEN, {
  debug: ENV_VARS.DEBUG_MIXPANEL,
  track_pageview: true,
  persistence: 'localStorage'
});

Resources.config({
  WebClient: ENV_VARS.WEB_CLIENT_HOST
});

function ClientRouter() {
  return (
    <>
      <CssBaseline />
      <App>
        <Suspense fallback={<Loader />}>
          <TeamsRoutes />
        </Suspense>
      </App>
    </>
  );
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<ClientRouter />);