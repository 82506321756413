import React, { useState } from 'react';
import ToastContext from './ToastContext';
import { Snackbar, Alert } from '@mui/material';

const StyledAlert = React.forwardRef((props, ref) => {
  return <Alert elevation={6} ref={ref} variant='filled' {...props} />;
});

export default function ToastProvider({ children }) {
  const [toastProps, setToastProps] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const { status, message } = toastProps || {};

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsOpen(false);
  };

  return (
    <ToastContext.Provider
      value={{
        openToast: (props) => {
          setToastProps(props);
          setIsOpen(true);
        },
        toastOptions: {
          error: 'error',
          warning: 'warning',
          success: 'success'
        }
      }}
    >
      <Snackbar 
        open={isOpen} 
        autoHideDuration={3000} 
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <StyledAlert onClose={handleClose} severity={status} sx={{ width: '100%' }}>
          {message}
        </StyledAlert>
      </Snackbar>
      {children}
    </ToastContext.Provider>
  );
}
