import { createTheme } from '@mui/material/styles';
import './global.css';
import theme from '@matterapp/matter-theme';

export default createTheme({
  palette: {
    ...theme.colors,
    primary: {
      main: '#4584FF',
      darker: '#3466F2',
    },
    error: {
      main: '#ff4369'
    },
    white: {
      main: '#FFF'
    },
    grey: {
      main: '#828285',
      darker: '#5E5E5E',
      lighter: '#F6F6F6',
      light: '#666666',
      disabled: '#EBEBEB'
    },
    black: {
      main: '#000',
      lighter: 'rgb(62, 62, 66)'
    },
    green: {
      main: '#007A5A',
      lighter: '#07BB87'
    },
    purple: {
      main: '#5B5FC7',
      darker: '#4F52B2',
      dark: '#444791',
      light: '#E6F2FF',
      lighter: '#7C7FF2',
      darkButton: '#343799'
    },
    blue: {
      lighter: '#F3F8FF'
    }
  },
  typography: {
    fontFamily: ['Inter UI', 'sans-serif'].join(','),
    fontSize: 14,
    h1: {
      fontSize: '34px'
    },
    h2: {
      fontSize: '2rem'
    },
    h3: {
      fontSize: '24px'
    },
    h4: {
      fontWeight: 600,
      fontSize: '1.4rem'
    },
    h5: {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '24px'
    },
    h6: {
      fontWeight: 'normal',
      fontSize: '17px',
      lineHeight: '24px'
    },
    h7: {
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '22px'
    },
    body1: {
      fontSize: '17px',
      lineHeight: '24px'
    },
    body2: {
      fontSize: '14px',
      lineHeight: '24px'
    }
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#4584FF',
          textDecoration: 'none',
          '&:hover': {
            color: '#25277A'
          }
        }
      }
    }
  }
});
