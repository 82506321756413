import React from 'react';
import { Helmet } from 'react-helmet';
import apolloClient from 'services/apolloClient';
import { ApolloProvider } from '@apollo/client';
import theme from 'theme';
import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';

const { DEPLOY_ENV } = ENV_VARS;

Sentry.init({
  dsn: 'https://f232d8e594844b1cb0bbca47e43e32fc@o130359.ingest.sentry.io/4503984025894912',
  environment: DEPLOY_ENV,
  attachStacktrace: true,
  tracesSampleRate: 1.0
});

function FallbackComponent(args) {
  return (
    <div>An error has occured {JSON.stringify(args)}</div>
  );
}

export default function App({ children }) {
  return (
    <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={apolloClient}>
          <Helmet
            defaultTitle="Matter"
            titleTemplate="%s | Matter"
          />
          {children}
        </ApolloProvider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  );
}
