import React, { useEffect, useState } from 'react';
import DialogContext from './DialogContext';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  DialogContentText, 
  Button 
} from '@mui/material';

export default function DialogProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const [dialogProps, setDialogProps] = useState(null);

  const { 
    onClose = () => {}, 
    onCancel = () => {},
    onConfirm, 
    DialogBody, 
    header, 
    cancelButtonLabel = 'Cancel', 
    confirmButtonLabel = 'Yes, Proceed',
    confirmButtonColor = 'error'
  } = dialogProps || {};

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  useEffect(() => {
    if (dialogProps) {
      setIsOpen(true);
    }
  }, [dialogProps]);

  useEffect(() => {
    if (!isOpen) {
      setDialogProps(null);
    }
  }, [isOpen]);

  return (
    <DialogContext.Provider
      value={{
        openDialog: (props) => {
          setDialogProps(props);
        }
      }}
    >
      <Dialog
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
          sx: {
            padding: '1rem'
          }
        }}
      >
        <DialogTitle variant="h5">
          {header || 'Are you sure?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {DialogBody ? <DialogBody /> : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            variant="outlined" 
            onClick={() => {
              onCancel();
              handleClose();
            }}>
            {cancelButtonLabel}
          </Button>
          <Button 
            variant="contained" 
            disableElevation
            color={confirmButtonColor}
            onClick={() => {
              onConfirm();
              handleClose();
            }} 
            autoFocus
          >
            {confirmButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
      {children}
    </DialogContext.Provider>
  );
}
