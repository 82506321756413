import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const { GRAPHQL_ENDPOINT_URI, IS_DEV } = ENV_VARS;

const cache = new InMemoryCache();

const httpLink = createHttpLink({
  uri: GRAPHQL_ENDPOINT_URI,
});

localStorage.removeItem('matterTeamsToken');

const authLink = setContext((_, { headers }) => {
  const teamsToken = localStorage.getItem('matterTeamsToken');

  return {
    headers: {
      ...headers,
      authorization: teamsToken ? `MatterTeams ${teamsToken}` : '',
    }
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
  connectToDevTools: IS_DEV
});

export default client;
