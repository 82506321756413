import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ToastContext from 'context/ToastContext';
import DialogContext from 'context/DialogContext';
import { app } from '@microsoft/teams-js';

app.initialize();

const TeamsTab = React.lazy(() => import('views/teams/TeamsTab'));
const CompleteAuth = React.lazy(() => import('views/teams/Auth/CompleteAuth'));
const StartAuth = React.lazy(() => import('views/teams/Auth/StartAuth'));
const InitialSettings = React.lazy(() => import('views/teams/InitialSettings/InitialSettings'));
const WelcomeTab = React.lazy(() => import('views/teams/WelcomeTab'));
const InstructionsTab = React.lazy(() => import('views/teams/InstructionsTab'));
const GiveKudos = React.lazy(() => import('views/teams/TaskModals/GiveKudos'));
const AddCoins = React.lazy(() => import('views/teams/TaskModals/AddCoins'));
const TransferChannel = React.lazy(() => import('views/teams/TaskModals/TransferChannel'));

function TeamsRoutes() {
  return (
    <ToastContext.Provider>
      <DialogContext.Provider>
        <BrowserRouter>
          <Routes>
            <Route exact path="/msteams/tab" element={<TeamsTab />} />
            <Route path="/msteams/authComplete" element={<CompleteAuth />} />
            <Route path="/msteams/authStart" element={<StartAuth />} />
            <Route path="/msteams/initialSettings" element={<InitialSettings />} />
            <Route path="/msteams/welcome" element={<WelcomeTab />} />
            <Route path="/msteams/welcome-skip" element={<InstructionsTab />} />
            <Route path="/msteams/task/giveKudos/:workspaceId" element={<GiveKudos />} />
            <Route path="/msteams/task/addCoins/:workspaceId" element={<AddCoins />} />
            <Route path="/msteams/task/giveKudosExtension" element={<GiveKudos extension />} />
            <Route path="/msteams/task/transferChannel/:workspaceId" element={<TransferChannel />} />
          </Routes>
        </BrowserRouter>
      </DialogContext.Provider>
    </ToastContext.Provider>
  );
}

export default TeamsRoutes;
